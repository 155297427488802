import { IngredientType } from "../models/IngredientType";
import { ContextType } from "../store/Store";
import { PlaygroundType } from "../models/PlaygroundType";
import { FamiliyMemberType } from "../models/FamiliyMemberType";
import { ActivityType } from "../models/ActivityType";

export const toggleIngredientInShoppingList = (
  ingredient: IngredientType,
  playground: PlaygroundType,
  context: ContextType
) => {
  if (playground.shoppingList.ingredients.includes(ingredient.id)) {
    playground.shoppingList.ingredients = playground.shoppingList.ingredients.filter(
      (ingredientId) => {
        return ingredientId !== ingredient.id;
      }
    );
  } else {
    playground.shoppingList.ingredients.push(ingredient.id);
  }

  updateShoppinglistInStore(playground, context);
};

export const toggleActivityInShoppingList = (
  activity: ActivityType,
  playground: PlaygroundType,
  context: ContextType
) => {
  if (playground.shoppingList.activities.includes(activity.id)) {
    playground.shoppingList.activities = playground.shoppingList.activities.filter(
      (activityId) => {
        return activityId !== activity.id;
      }
    );
  } else {
    playground.shoppingList.activities.push(activity.id);
  }

  updateShoppinglistInStore(playground, context);
};

export const setFavoriteIngredient = (
  ingredient: IngredientType,
  playground: PlaygroundType,
  context: ContextType
) => {
  if (playground.shoppingList.favoriteIngredient === ingredient.id) {
    playground.shoppingList.favoriteIngredient = null;
  } else {
    playground.shoppingList.favoriteIngredient = ingredient.id;
  }

  updateShoppinglistInStore(playground, context);
};

export const setNewFamilyValue = (
  familyMember: FamiliyMemberType,
  playground: PlaygroundType,
  context: ContextType,
  newValue: number
) => {
  let isSet = false;
  playground.shoppingList.family.forEach((listFamilyMember) => {
    if (listFamilyMember.id === familyMember.id) {
      listFamilyMember.count = newValue;
      isSet = true;
    }
  });

  if (!isSet) {
    playground.shoppingList.family.push({
      id: familyMember.id,
      count: newValue,
    });
  }

  updateShoppinglistInStore(playground, context);
};

const updateShoppinglistInStore = (
  playground: PlaygroundType,
  context: ContextType
) => {
  const { state, dispatch } = context;

  if (state.playgrounds === null) {
    return;
  }
  const playgrounds: Array<PlaygroundType> = [];
  state.playgrounds.forEach((statePlayground) => {
    if (statePlayground.id === playground.id) {
      statePlayground = playground;
    }
    playgrounds.push(statePlayground);
  });

  dispatch({
    type: "SET_PLAYGROUNDS",
    payload: playgrounds,
  });
};

export const getCurrentPoints = (playground: PlaygroundType): number => {
  let points = 0;

  playground.ingredients.forEach((ingredient) => {
    if (playground.shoppingList.ingredients.includes(ingredient.id)) {
      points += ingredient.points;
    }
  });

  return points;
};
