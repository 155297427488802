import React from "react";
import { IngredientType } from "../../models/IngredientType";
import { Image } from "../atoms/Image";
import { CheckBox } from "../atoms/Checkbox";
import { PlaygroundType } from "../../models/PlaygroundType";

export type IngredientTileProps = {
  ingredient: IngredientType;
  playground: PlaygroundType;
  selectIngredient: (ingredient: IngredientType) => void;
  favoriteIngredient: (ingredient: IngredientType) => void;
};

export const IngredientTile = (props: IngredientTileProps) => {
  const selectIngredient = () => {
    props.selectIngredient(props.ingredient);
  };
  const favoriteIngredient = () => {
    props.favoriteIngredient(props.ingredient);
  };
  return (
    <div className={"ingredientTile tile"}>
      <div className={"ingredientTile__actions"}>
        <CheckBox
          ariaText={props.ingredient.title + " in boodschappen"}
          checked={props.playground.shoppingList.ingredients.includes(
            props.ingredient.id
          )}
          checkboxId={"ingredient-" + props.ingredient.id}
          onChangeSetStateFunction={selectIngredient}
        />
        <CheckBox
          ariaText={props.ingredient.title + " als favoriet"}
          checked={
            props.playground.shoppingList.favoriteIngredient ===
            props.ingredient.id
          }
          onChangeSetStateFunction={favoriteIngredient}
          useStar={true}
        />
      </div>
      <div className={"ingredientTile__image tile__image"}>
        <Image image={props.ingredient.image} onClick={selectIngredient} />
      </div>
      <div className={"ingredientTile__title"}>
        <label htmlFor={"ingredient-" + props.ingredient.id}>
          {props.ingredient.title}
        </label>
      </div>
      <div className={"ingredientTile__costs"}>
        Kosten: {getCostImages(props.ingredient.points)}
      </div>
    </div>
  );
};

export const getCostImages = (points: number): React.ReactNode => {
  const images = [];
  for (let count = 0; count < points; count++) {
    images.push(
      <img src={"/assets/images/coin.svg"} key={count} alt={"Muntje"} />
    );
  }

  return images;
};
