import React from "react";

export const NoPlaygrounds = () => {
  return (
    <div>
      <h1>Geen speeltuinen beschikbaar</h1>
      <p>
        Voor uw adres zijn er op het moment geen speelplekken beschikbaar voor
        participatie. Vragen? Neem contact met ons op via{" "}
        {process.env.REACT_APP_CLIENT_CONTACT_EMAIL}.
      </p>
    </div>
  );
};
