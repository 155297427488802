import React from "react";
import { ActivityType } from "../../models/ActivityType";
import { Image } from "../atoms/Image";
import { CheckBox } from "../atoms/Checkbox";
import { PlaygroundType } from "../../models/PlaygroundType";

export type ActivityTileProps = {
  activity: ActivityType;
  playground: PlaygroundType;
  selectActivity: (activity: ActivityType) => void;
};

export const ActivityTile = (props: ActivityTileProps) => {
  const selectActivity = () => {
    props.selectActivity(props.activity);
  };
  return (
    <div className={"ingredientTile tile"}>
      <div className={"ingredientTile__actions"}>
        <CheckBox
          ariaText={"Selecteer " + props.activity.title}
          checked={props.playground.shoppingList.activities.includes(
            props.activity.id
          )}
          onChangeSetStateFunction={selectActivity}
        />
      </div>
      <div className={"ingredientTile__image tile__image"}>
        <Image image={props.activity.image} onClick={selectActivity} />
      </div>
      <div className={"ingredientTile__title"}>{props.activity.title}</div>
    </div>
  );
};
