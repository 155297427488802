import { SessionState, initialSessionState } from "./SessionState";
import { initialUserState, UserState } from "./UserState";
import { PlaygroundType } from "../../models/PlaygroundType";

export type SpeeltuinchefState = {
  session: SessionState;
  user: UserState;
  playgrounds: Array<PlaygroundType> | null;
};

const initialState: SpeeltuinchefState = {
  session: initialSessionState,
  user: initialUserState,
  playgrounds: null,
};

export { initialState };
