import React, { Dispatch, SetStateAction } from "react";

export type TileExplenationType = {
  imageUrl: string;
  imageDescription: string;
  title: string;
  text: string;
  setSeenDescription: Dispatch<SetStateAction<boolean>>;
};
export const TileExplanation = (props: TileExplenationType) => {
  return (
    <div className={"block tileExplenation"}>
      <img src={props.imageUrl} alt={props.imageDescription} />
      <h1>{props.title}</h1>
      <p>{props.text}</p>
      <button
        className={"button"}
        onClick={() => {
          props.setSeenDescription(true);
        }}
      >
        Ik snap het!
      </button>
    </div>
  );
};
