import React from "react";
import { hasValidSession } from "../../helpers/SessionHelper";
import { useContext } from "react";
import { SpeeltuinchefContext } from "../../store/Store";
import { Link } from "react-router-dom";

export const NavigationBar = () => {
  const context = useContext(SpeeltuinchefContext);

  const navigationItems = hasValidSession(context)
    ? getNavigationItems()
    : null;

  return (
    <header>
      <nav className={"navigation"}>
        <img
          src="/assets/images/logo_speeltuinchef.png"
          alt={"Logo Speeltuinchef"}
          className={"navigation__logoSpeeltuinchef"}
        />
        {navigationItems}
        <img
          src={process.env.REACT_APP_CLIENT_LOGO_FILE}
          alt={"In samenwerking met " + process.env.REACT_APP_CLIENT_NAME}
          className={"navigation__logoClient"}
        />
      </nav>
    </header>
  );
};

const getNavigationItems = () => {
  return (
    <div className={"navigation__items"}>
      <a className={"navigation__skiplink"} href="#main">
        Naar content
      </a>
      <Link className={"navigation__item"} to={"/playgrounds"}>
        <img src={"/assets/images/home.svg"} alt={"Naar mijn speeltuinen"} />
      </Link>
      <Link className={"navigation__item"} to={"/personal"}>
        <img
          src={"/assets/images/profile.svg"}
          alt={"Naar de persoonlijke gegevens"}
        />
      </Link>
      <a id={"main"} />
    </div>
  );
};

export default NavigationBar;
