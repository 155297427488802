import { UserResponse } from "../../models/response/UserResponse";
import { UpdateAddressRequest } from "../../models/request/UpdateAddressRequest";
import { ErrorResponse } from "../../models/response/ErrorResponse";
import { revokeSession } from "../../helpers/SessionHelper";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getUser = (sessionToken: string): Promise<UserResponse> => {
  return fetch(BASE_URL + "/user/me", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        revokeSession();
        return;
      }
      return response.json();
    })
    .then((userResponse) => userResponse);
};

export const postUpdateUserAddress = (
  address: UpdateAddressRequest,
  sessionToken: string
): Promise<ErrorResponse> => {
  return fetch(BASE_URL + "/user/updateAddress", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(address),
  })
    .then((response) => response.json())
    .then((addressResponse) => addressResponse)
    .catch((e) => {
      console.error(e);
    });
};

export const requestPasswordReset = (email: string): Promise<[]> => {
  return fetch(BASE_URL + "/user/passwordReset", {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .then((response) => response.json())
    .then((resetResponse) => resetResponse)
    .catch((e) => {
      console.error(e);
    });
};

export const requestPasswordNew = (
  password: string,
  token: string
): Promise<[]> => {
  return fetch(BASE_URL + "/user/passwordNew", {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: JSON.stringify({ token: token, password: password }),
  })
    .then((response) => response.json())
    .then((resetResponse) => resetResponse)
    .catch((e) => {
      console.error(e);
    });
};

export const deleteUserSession = (sessionToken: string): Promise<[]> => {
  return fetch(BASE_URL + "/user/logout", {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => response.json())
    .then((logoutResponse) => logoutResponse);
};
