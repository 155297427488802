import React, { useState } from "react";
import BottomPaginationBar from "./BottomPaginationBar";
import { quizQuestions } from "../../assets/data/quizQuestions";
import { QuizQuestion } from "../molecules/QuizQuestion";
import { Link } from "react-router-dom";

export const QuizContainer = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState(Array(quizQuestions.length));
  const [stateIncrement, setStateIncrement] = useState(0);
  const [isResultPage, setIsResultPage] = useState(false);

  const storeAnswer = (questionIndex: number, answerIndex: number) => {
    answers[questionIndex] = answerIndex;
    setAnswers(answers);
    setStateIncrement(stateIncrement + 1);
  };

  const getSelectedOption = (stateIncrement: number): number | undefined => {
    if (stateIncrement > 0) {
      return answers[currentPage];
    }
    return answers[currentPage];
  };

  const handleButtonClick = () => {
    if (currentPage + 1 < quizQuestions.length) {
      setCurrentPage(currentPage + 1);
    } else {
      setIsResultPage(true);
    }
  };

  const getResultPoints = (): number => {
    let points = 0;
    answers.forEach((answer, index) => {
      points += quizQuestions[index].options[answer].points;
    });

    return points;
  };

  const getResultTitle = (): string => {
    const points = getResultPoints();
    if (points > 19) {
      return "Thuisblijver";
    } else if (points > 14) {
      return "Speeltuinmaker";
    } else if (points > 9) {
      return "Speeltuinontdekker";
    } else {
      return "Speeltuindromer";
    }
  };

  const getResultText = (): string => {
    const points = getResultPoints();
    if (points > 19) {
      return "Leuk hoor al die speeltuin praat, maar mijn tuin is de beste speeltuin die er is.";
    } else if (points > 14) {
      return "Je ziet de speeltuin als jouw bouwplaats. Alles wat er nog niet is, kan jij wel bouwen.";
    } else if (points > 9) {
      return "Elk hoekje van de speeltuin is interessant. Jij weet al vrij snel welke diertjes er allemaal in de speeltuin leven.";
    } else {
      return "Als jij buiten speelt ben je helemaal in een andere wereld. Alles en iedereen kan van alles worden.";
    }
  };

  const getResultImage = (): React.ReactNode => {
    const points = getResultPoints();
    if (points > 19) {
      return <img src={"/assets/images/quiz-thuisblijver.svg"} alt={""} />;
    } else if (points > 14) {
      return <img src={"/assets/images/quiz-speeltuinmaker.svg"} alt={""} />;
    } else if (points > 9) {
      return (
        <img src={"/assets/images/quiz-speeltuinontdekker.svg"} alt={""} />
      );
    } else {
      return <img src={"/assets/images/quiz-speeltuindromer.svg"} alt={""} />;
    }
  };

  if (isResultPage) {
    return (
      <div className={"block quizQuestion__result"}>
        {getResultImage()} <h1>{getResultTitle()}</h1> <p>{getResultText()}</p>
        <Link className={"button"} to={"/playgrounds"}>
          Verder!
        </Link>
      </div>
    );
  } else {
    return (
      <div className={"block"}>
        <QuizQuestion
          questionIndex={currentPage}
          selectedOptionIndex={getSelectedOption(stateIncrement)}
          storeAnswer={storeAnswer}
        />
        <div>
          <button
            className={"button quizQuestion__button"}
            onClick={() => {
              handleButtonClick();
            }}
          >
            {currentPage + 1 === quizQuestions.length
              ? "Bekijk resultaat"
              : "Volgende"}
          </button>
        </div>
        <BottomPaginationBar
          count={quizQuestions.length}
          currentPage={currentPage}
          hasVoting={false}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  }
};
