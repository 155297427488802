import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SpeeltuinchefContext } from "../../store/Store";

export const PersonalOverview = () => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;

  return (
    <div className={"block personalOverview shoppingList"}>
      <section>
        <h2>Persoonlijk</h2>
        {state.user.email}
        <br />
        {state.user.address?.postalCode} - {state.user.address?.housenumber}{" "}
        {state.user.address?.addition}
      </section>
      <section>
        <Link to={"/logout"} className={"button"}>
          Uitloggen
        </Link>
      </section>
      <section>
        <h2>Vragen?</h2>
        <p>Mail ons op: {process.env.REACT_APP_CLIENT_CONTACT_EMAIL}</p>
      </section>
      <section>
        <h2>Over deze applicatie?</h2>
        <p>
          Meer informatie via{" "}
          <a href={"https://www.speeltuinchef.nl"}>onze website</a>
        </p>
      </section>
      <div className={"sitemap__link"}>
        <Link to={"/sitemap"} arial-label={"Bezoek de sitemap"}>
          Sitemap
        </Link>
      </div>
    </div>
  );
};
