export const quizQuestions = [
  {
    title: "Vraag 1",
    question: "Met wie zou jij het liefst buiten op pad gaan?",
    options: [
      {
        text: "Met mijn broertje/zusje.",
        points: 1,
      },
      {
        text: "Met mijn huisdier.",
        points: 2,
      },
      {
        text: "Samen met mijn vriendjes.",
        points: 3,
      },
      {
        text: "Alleen.",
        points: 4,
      },
    ],
  },
  {
    title: "Vraag 2",
    question: "Wat zou jij kiezen om mee naar buiten te nemen?",
    options: [
      {
        text: "Mijn schep, hark en emmer.",
        points: 3,
      },
      {
        text: "Niets.",
        points: 1,
      },
      {
        text:
          "Niet zoveel, want ik kan altijd even naar binnen om iets te pakken.",
        points: 4,
      },
      {
        text: "Mijn vergrootglas, schepnet, schrift en/of pen.",
        points: 2,
      },
    ],
  },
  {
    title: "Vraag 3",
    question: "Welk van deze buitenspellen vind jij het leukst?",
    options: [
      {
        text: "Verstoppertje.",
        points: 2,
      },
      {
        text: "Trampoline springen.",
        points: 4,
      },
      {
        text: "Ik verzin elke keer iets nieuws.",
        points: 1,
      },
      {
        text: "Wie de hoogste toren kan maken.",
        points: 3,
      },
    ],
  },
  {
    title: "Vraag 4",
    question: "Waar zou jij het liefst spelen als je naar buiten gaat?",
    options: [
      {
        text: "In mijn eigen tuin.",
        points: 4,
      },
      {
        text:
          "Maakt mij niet zoveel uit, ik maak er altijd wel iets leuks van. ",
        points: 3,
      },
      {
        text: "Elke keer ergens anders.",
        points: 2,
      },
      {
        text: "Meestal in de buurt maar elke omgeving vind ik leuk.",
        points: 1,
      },
    ],
  },
  {
    title: "Vraag 5",
    question: "Welk van deze speeltoestellen vind jij het leukst?",
    options: [
      {
        text: "Klimtoestel.",
        points: 2,
      },
      {
        text: "Schommel.",
        points: 1,
      },
      {
        text: "Zandbak.",
        points: 3,
      },
      {
        text: "Wipkip.",
        points: 4,
      },
    ],
  },
  {
    title: "Vraag 6",
    question: "Welk van deze activiteiten vind jij het leukst?",
    options: [
      {
        text: "Boeken lezen en/of films kijken.",
        points: 1,
      },
      {
        text: "Buiten spelen.",
        points: 2,
      },
      {
        text: "Computerspelletjes spelen.",
        points: 3,
      },
      {
        text: "Knutselen.",
        points: 4,
      },
    ],
  },
];
