import React, { ChangeEvent, useState } from "react";
import { QuestionType } from "../../models/QuestionType";
import { ShoppinglistType } from "../../models/ShoppinglistType";

import { AnswerType } from "../../models/AnswerType";
import { CheckBox } from "../atoms/Checkbox";

export type QuestionnaireQuestionProps = {
  question: QuestionType;
  shoppingList: ShoppinglistType;
};
export const QuestionnaireQuestion = (props: QuestionnaireQuestionProps) => {
  const [refresh, setRefresh] = useState(0);
  const getQuestion = (): React.ReactNode => {
    if (props.question.type === "text") {
      return getTextQuestion();
    } else if (props.question.type === "select") {
      return getSelectQuestion();
    } else if (props.question.type === "checkbox") {
      return getCheckboxQuestion();
    }
  };

  const changeAnswer = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let isSet = false;
    props.shoppingList.answers.forEach((answer) => {
      if (answer.questionId === props.question.id) {
        answer.answer = event.target.value;
        isSet = true;
      }
    });

    if (!isSet) {
      const answer: AnswerType = {
        questionId: props.question.id,
        answer: event.target.value,
      };

      props.shoppingList.answers.push(answer);
    }

    setRefresh(refresh + 1);
  };

  const getTextQuestion = (): React.ReactNode => {
    let value = "";
    props.shoppingList.answers.forEach((answer) => {
      if (answer.questionId === props.question.id) {
        value = answer.answer;
      }
    });

    return (
      <div>
        <input
          type={"text"}
          aria-label={props.question.question}
          value={value}
          onChange={changeAnswer}
        />
      </div>
    );
  };

  const toggleCheckboxQuestion = (answerText: string): void => {
    let selectedAnswers: Array<string> = [];
    props.shoppingList.answers.forEach((answer) => {
      if (answer.questionId === props.question.id) {
        selectedAnswers.push(answer.answer);
      }
    });

    let newAnswers: Array<AnswerType> = [];
    if (selectedAnswers.includes(answerText)) {
      newAnswers = props.shoppingList.answers.filter((answer) => {
        return (
          answer.questionId !== props.question.id ||
          answer.answer !== answerText
        );
      });
    } else {
      const answer: AnswerType = {
        questionId: props.question.id,
        answer: answerText,
      };
      newAnswers = [...props.shoppingList.answers, answer];
    }

    props.shoppingList.answers = newAnswers;
    setRefresh(refresh + 1);
  };

  const getCheckboxQuestion = (): React.ReactNode => {
    let selectedAnswers: Array<string> = [];
    props.shoppingList.answers.forEach((answer) => {
      if (answer.questionId === props.question.id) {
        selectedAnswers.push(answer.answer);
      }
    });

    const checkboxQuestionnaireChange = (text: string): void => {
      toggleCheckboxQuestion(text);
    };

    return props.question.options.map((option: string, index: number) => {
      return (
        <div key={props.question.id + "-" + index}>
          <CheckBox
            ariaText={option}
            text={option}
            checked={selectedAnswers.includes(option)}
            onChangeQuestionnaireFunction={checkboxQuestionnaireChange}
          />
        </div>
      );
    });
  };

  const getSelectQuestion = (): React.ReactNode => {
    let selectedAnswer = "";
    props.shoppingList.answers.forEach((answer) => {
      if (answer.questionId === props.question.id) {
        selectedAnswer = answer.answer;
      }
    });

    return (
      <div>
        <select
          aria-label={props.question.question}
          value={selectedAnswer}
          onChange={changeAnswer}
        >
          <option key={"placeholder"} value={""}>
            Maak een keuze
          </option>
          {props.question.options.map((option: string, index: number) => {
            return (
              <option key={props.question.id + "-" + index} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  return (
    <div className={"questionList__question"}>
      <fieldset>
        <legend className={"questionList__question__text"}>
          {props.question.question}
        </legend>
        {getQuestion()}
      </fieldset>
    </div>
  );
};
