import React, { useContext, useState } from "react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { QuestionType } from "../../models/QuestionType";
import { ShoppinglistType } from "../../models/ShoppinglistType";
import { QuestionnaireQuestion } from "../molecules/QuestionnaireQuestion";
import { updateShoppingList } from "../../api/playgrounds/PlaygroundsCalls";
import { SpeeltuinchefContext } from "../../store/Store";
import { useNavigate } from "react-router-dom";
import { BottomActionBar } from "./BottomActionBar";

export type QuestionListProps = {
  playground: PlaygroundType;
};

export const QuestionList = (props: QuestionListProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(SpeeltuinchefContext);
  const navigate = useNavigate();

  const saveAndContinue = async () => {
    const { state } = context;
    if (state.session.token === null) {
      location.href = "/login";
      return;
    }
    setIsLoading(true);
    await updateShoppingList(
      state.session.token,
      props.playground,
      props.playground.shoppingList
    );
    navigate("/playground/" + props.playground.id);
  };

  const getQuestions = (
    questions: Array<QuestionType>,
    shoppingList: ShoppinglistType
  ): Array<React.ReactNode> => {
    return questions.map((question) => {
      return (
        <QuestionnaireQuestion
          key={question.id}
          question={question}
          shoppingList={shoppingList}
        />
      );
    });
  };

  return (
    <div className={"block questionList"}>
      <h1>{props.playground.name}</h1>
      <div className={"questionList__questions"}>
        {getQuestions(
          props.playground.questions,
          props.playground.shoppingList
        )}
      </div>
      <BottomActionBar
        text={"Opslaan"}
        isLoading={isLoading}
        clickFunction={saveAndContinue}
      />
    </div>
  );
};
