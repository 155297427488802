import React, { useEffect, useState } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { TileExplanation } from "../components/molecules/TileExplanation";
import { Remark } from "../components/organisms/Remark";

export const PlaygroundRemarkPage = () => {
  const [seenDescription, setSeenDescription] = useState(false);

  useEffect(() => {
    document.title = "Jullie aanvullingen - Speeltuinchef";
  });

  if (!seenDescription) {
    return (
      <div>
        <NavigationBar />
        <main>
          <TileExplanation
            imageUrl={"/assets/images/remark_tile.svg"}
            imageDescription={""}
            title={"Jullie aanvullingen"}
            text={
              "Hebben jullie alles ingevuld maar hebben jullie nog andere ideeën, opmerkingen, gedachtes, vragen, enzovoort? Hier kan je ze kwijt."
            }
            setSeenDescription={setSeenDescription}
          />
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <NavigationBar />
        <main>
          <Remark />
        </main>
      </div>
    );
  }
};
