import React, { KeyboardEvent, useEffect, useState } from "react";
import { quizQuestions } from "../../assets/data/quizQuestions";

export type QuizQuestionProps = {
  questionIndex: number;
  storeAnswer: (questionIndex: number, answerIndex: number) => void;
  selectedOptionIndex: number | undefined;
};

export const QuizQuestion = (props: QuizQuestionProps) => {
  const question = quizQuestions[props.questionIndex];
  const [state, updateState] = useState(0);

  useEffect(() => {
    document.title = question.title + " - Quiz - Speeltuinchef";
  });

  const answers = () => {
    return quizQuestions[props.questionIndex].options.map((answer, index) => {
      return (
        <label
          tabIndex={0}
          key={index}
          role={"radio"}
          aria-checked={props.selectedOptionIndex === index}
          onKeyPress={(e: KeyboardEvent) => {
            if (e.key === " ") {
              props.storeAnswer(props.questionIndex, index);
              updateState(state + 1);
            }
          }}
        >
          <div
            className={
              props.selectedOptionIndex === index
                ? "quizQuestion__answer quizQuestion__answer-selected"
                : "quizQuestion__answer"
            }
            onClick={() => {
              props.storeAnswer(props.questionIndex, index);
              updateState(state + 1);
            }}
          >
            <img
              src={
                props.selectedOptionIndex === index
                  ? "/assets/images/quizQuestionOption-selected.svg"
                  : "/assets/images/quizQuestionOption.svg"
              }
              alt={""}
            />{" "}
            <span className={"quizQuestion__answer-label"}>
              <label>{answer.text}</label>
            </span>
          </div>
        </label>
      );
    });
  };

  return (
    <div className={"quizQuestion"}>
      <fieldset>
        <div className={"quizQuestion__header"}>
          <h2>{question.title}</h2>
          <legend>{question.question}</legend>
        </div>
        {answers()}
      </fieldset>
    </div>
  );
};
