import BottomPaginationBar from "./BottomPaginationBar";
import React, { useContext, useState } from "react";
import SkipOnboarding from "../molecules/SkipOnboarding";
import { useNavigate } from "react-router-dom";
import { SpeeltuinchefContext } from "../../store/Store";
import { setRememberMe } from "../../api/SpeeltuinchefApi";
import {
  getSessionFromStorage,
  revokeSession,
} from "../../helpers/SessionHelper";

export const Onboarding = () => {
  const pageCount = 4;
  const [currentPage, setCurrentPage] = useState(0);
  const context = useContext(SpeeltuinchefContext);
  const { state, dispatch } = context;
  const navigate = useNavigate();

  const finishOnboarding = async () => {
    const accessToken = state.session.token
      ? state.session.token
      : getSessionFromStorage();

    try {
      const response = await setRememberMe(accessToken);
      dispatch({ type: "SET_USER", payload: response });
      navigate("/playgrounds");
    } catch (e) {
      revokeSession();
    }
  };

  return (
    <div className={"onboarding"}>
      {pageCount == currentPage + 1 ? (
        <button
          className={"button onboarding__skipbutton"}
          onClick={() => {
            finishOnboarding();
          }}
        >
          Aan de slag!
        </button>
      ) : (
        <SkipOnboarding skipFunction={finishOnboarding} />
      )}
      <div>
        <div className={"onboarding__cloud"}>
          <div className={"onboarding__cloud-title"}>
            <h1>{getOnboardingTitle(currentPage)}</h1>
          </div>
          <div className={"onboarding__cloud-message"}>
            {getOnboardingText(currentPage)}
          </div>
          <div className={"onboarding__cloud-speakingtip"}>
            <img src={"/assets/images/cloudSpeech.svg"} alt={"Gespreks wolk"} />
          </div>
        </div>
        <div className={"onboarding__chef"}>
          <img
            src={"/assets/images/chef_big_empty.png"}
            alt={"Chef die tekst uitspreekt"}
          />
        </div>
      </div>
      <BottomPaginationBar
        count={pageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        hasVoting={false}
      />
    </div>
  );
};

const getOnboardingText = (page: number): string => {
  const text = [
    "Door alle ingestuurde boodschappenlijstjes laat ik mij inspireren en kook ik verschillende gerechten. Als ze klaar zijn krijg je een seintje en kan er gestemd worden op je favoriet. Tot slot krijg je per onderdeel nog extra uitleg. Succes!",
    "Het idee is heel simpel: je geeft je boodschappenlijstje door en ik kook daar een mooie speelplek van. Dat is alles!",
    "Via het hoofdmenu kan je mij van alles laten weten: wie er bij je thuis woont, wat je mooi vindt, wat je graag buiten doet en wat je op het speelterrein zou willen hebben. Je kan ook een leuke quiz doen en zien hoe lang je nog hebt om je boodschappenlijstje aan mij door te geven.",
    "Door alle ingestuurde boodschappenlijstjes laat ik mij inspireren en kook ik verschillende gerechten. Als ze klaar zijn krijg je een seintje en kan er gestemd worden op je favoriet. Tot slot krijg je per onderdeel nog extra uitleg. Succes!",
  ];

  return text[page];
};

const getOnboardingTitle = (page: number): string => {
  const titles = [
    "De Speeltuinchef",
    "Het idee",
    "Hoe werkt het?",
    "Hoe werkt het?",
  ];

  return titles[page];
};

export default Onboarding;
