import React from "react";
import { FamiliyMemberType } from "../../models/FamiliyMemberType";
import { Image } from "../atoms/Image";
import { FamilyMemberCounter } from "../organisms/FamilyMemberCounter";
import { ContextType } from "../../store/Store";
import { PlaygroundType } from "../../models/PlaygroundType";
import { setNewFamilyValue } from "../../helpers/ShoppinglistHelper";

export type FamilyTileProps = {
  familyMember: FamiliyMemberType;
  playground: PlaygroundType;
  context: ContextType;
};

export const FamilyTile = (props: FamilyTileProps) => {
  const decreaseFunction = () => {
    const newValue = getCurrentValue() === 0 ? 0 : getCurrentValue() - 1;
    updateNewValue(newValue);
  };
  const increaseFunction = () => {
    const newValue = getCurrentValue() + 1;
    updateNewValue(newValue);
  };

  const updateNewValue = (newValue: number) => {
    setNewFamilyValue(
      props.familyMember,
      props.playground,
      props.context,
      newValue
    );
  };

  const getCurrentValue = (): number => {
    let currentValue = 0;
    props.playground.shoppingList.family.forEach((familyMember) => {
      if (familyMember.id === props.familyMember.id) {
        currentValue = familyMember.count;
      }
    });

    return currentValue;
  };

  return (
    <div className={"tile"}>
      <div className={"tile__image"}>
        <Image image={props.familyMember.image} />
      </div>
      <div className={"tile__title"}>
        <h2>{props.familyMember.title}</h2>
      </div>
      <FamilyMemberCounter
        currentValue={getCurrentValue()}
        increaseFunction={increaseFunction}
        decreaseFunction={decreaseFunction}
        title={props.familyMember.title}
      />
    </div>
  );
};
