import React, { useContext } from "react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { SpeeltuinchefContext } from "../../store/Store";
import { sortPlaygroundAlphabetical } from "../../helpers/PlaygroundHelper";
import { Link } from "react-router-dom";

export const PlaygroundList = () => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;

  const playgrounds = state.playgrounds
    ? getRenderedPlaygrounds(state.playgrounds)
    : null;

  return (
    <div className={"playgroundlist"}>
      <h1>Mijn speeltuinen</h1>
      <div className={"playgroundlist__playgrounds"}>{playgrounds}</div>
      <div className={"sitemap__link"}>
        <Link to={"/sitemap"} arial-label={"Bezoek de sitemap"}>
          Sitemap
        </Link>
      </div>
    </div>
  );
};

const getRenderedPlaygrounds = (
  playgrounds: Array<PlaygroundType>
): Array<React.ReactNode> => {
  const sortedPlaygrounds = playgrounds.sort(sortPlaygroundAlphabetical);
  return sortedPlaygrounds.map((playground) => {
    return (
      <Link
        key={playground.id}
        to={"/playground/" + playground.id}
        aria-label={"Ga naar " + playground.name}
      >
        {playground.name}
      </Link>
    );
  });
};
