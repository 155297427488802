import { PlaygroundType } from "../../models/PlaygroundType";
import { ShoppinglistType } from "../../models/ShoppinglistType";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getPlaygrounds = (sessionToken: string): Promise<any> => {
  return fetch(BASE_URL + "/playgrounds/my", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => response.json())
    .then((playgroundsResponse) => playgroundsResponse);
};

export const updateShoppingList = (
  sessionToken: string,
  playground: PlaygroundType,
  shoppingList: ShoppinglistType
): Promise<any> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/updateList", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify(shoppingList),
  })
    .then((response) => response.json())
    .then((shoppingListResponse) => shoppingListResponse);
};

export const updateVote = (
  sessionToken: string,
  playground: PlaygroundType,
  shoppingList: ShoppinglistType
): Promise<any> => {
  return fetch(BASE_URL + "/playgrounds/" + playground.id + "/updateList", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
    body: JSON.stringify({
      votedConcept: shoppingList.votedConcept,
      votedConceptRemark: shoppingList.votedConceptRemark,
    }),
  })
    .then((response) => response.json())
    .then((shoppingListResponse) => shoppingListResponse);
};
