import { MoodboardType } from "../models/MoodboardType";
import { ShoppinglistType } from "../models/ShoppinglistType";

export const getIndexArrayOfSelectedMoodboards = (
  moodboards: Array<MoodboardType>,
  selectedMoodboards: Array<number>
): Array<number> => {
  const selectedIndex: Array<number> = [];

  moodboards.forEach((moodboard, index) => {
    if (selectedMoodboards.includes(moodboard.id)) {
      selectedIndex.push(index);
    }
  });

  return selectedIndex;
};

export const toggleMoodboard = (
  moodboard: MoodboardType,
  shoppingList: ShoppinglistType
) => {
  if (shoppingList.moodboards.includes(moodboard.id)) {
    shoppingList.moodboards = shoppingList.moodboards.filter(
      (listMoodboard) => {
        return listMoodboard !== moodboard.id;
      }
    );
  } else {
    shoppingList.moodboards.push(moodboard.id);
  }
};
