import React, { Dispatch, SetStateAction } from "react";

export interface BottomPaginationBarProps {
  count: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  hasVoting: boolean;
  votedPages?: Array<number>;
  votePositiveFunction?: (currentPage: number) => void;
  voteNegativeFunction?: (currentPage: number) => void;
  saveFunction?: () => void;
}

export const BottomPaginationBar = (props: BottomPaginationBarProps) => {
  return (
    <div className={"bottomPaginationBar"}>
      {props.hasVoting ? (
        <div className={"bottomPaginationBar__voting"}>
          <button
            aria-label={"Stem ja"}
            onClick={() => {
              if (props.votePositiveFunction) {
                props.votePositiveFunction(props.currentPage);
              }
              if (props.currentPage + 1 < props.count) {
                props.setCurrentPage(props.currentPage + 1);
              }
            }}
          >
            <img
              src={"/assets/images/smiley-positive.svg"}
              alt={"Groen blij kijkende smiley"}
            />
          </button>
          <button
            aria-label={"Stem nee"}
            onClick={() => {
              if (props.voteNegativeFunction) {
                props.voteNegativeFunction(props.currentPage);
              }
              if (props.currentPage + 1 < props.count) {
                props.setCurrentPage(props.currentPage + 1);
              }
            }}
          >
            <img
              src={"/assets/images/smiley-negative.svg"}
              alt={"Rood teleurgesteld kijkende smiley"}
            />
          </button>
        </div>
      ) : null}
      <div className={"bottomPaginationBar__navigation"}>
        {props.currentPage > 0 ? (
          <button
            aria-label={"Ga naar vorige pagina"}
            onClick={() => {
              props.setCurrentPage(props.currentPage - 1);
            }}
          >
            <img src={"/assets/images/arrow-left.svg"} alt={""} />
          </button>
        ) : null}
      </div>
      <div className={"bottomPaginationBar__indicators"}>
        {getPageIndicators(
          props.currentPage,
          props.count,
          props.hasVoting,
          props.votedPages
        )}
      </div>
      <div className={"bottomPaginationBar__navigation"}>
        {props.currentPage + 1 === props.count ? (
          props.saveFunction ? (
            <button
              className={"button"}
              onClick={() => {
                if (props.saveFunction) {
                  props.saveFunction();
                }
              }}
            >
              Verder
            </button>
          ) : null
        ) : (
          <button
            aria-label={"Ga naar volgende pagina"}
            onClick={() => {
              props.setCurrentPage(props.currentPage + 1);
            }}
          >
            <img src={"/assets/images/arrow-right.svg"} alt={""} />
          </button>
        )}
      </div>
    </div>
  );
};

const getPageIndicators = (
  current: number,
  count: number,
  hasVoting: boolean,
  votedPages?: Array<number>
): Array<React.ReactNode> => {
  let indicators: React.ReactNode[] = [];

  for (let indicator = 0; indicator < count; indicator++) {
    const indicatorHtml =
      indicator === current ? (
        <img
          src={
            hasVoting && votedPages && votedPages.includes(indicator)
              ? "/assets/images/circle-active.svg"
              : "/assets/images/circle-passive.svg"
          }
          alt={"Pagina " + (indicator + 1) + " actief"}
          key={"indicator_" + indicator}
          className={"bottomPaginationBar__indicators-active"}
        />
      ) : (
        <img
          src={
            hasVoting && votedPages && votedPages.includes(indicator)
              ? "/assets/images/circle-active.svg"
              : "/assets/images/circle-passive.svg"
          }
          alt={"Pagina " + (indicator + 1)}
          key={"indicator_" + indicator}
          className={"bottomPaginationBar__indicators-passive"}
        />
      );
    indicators.push(indicatorHtml);
  }

  return indicators;
};

export default BottomPaginationBar;
