import { LoginResponse } from "../models/response/LoginResponse";
import { LoginRequest } from "../models/request/LoginRequest";
import { UserResponse } from "../models/response/UserResponse";
import { RegisterRequest } from "../models/request/RegisterRequest";
import { RegisterResponse } from "../models/response/RegisterResponse";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const postLogin = (login: LoginRequest): Promise<LoginResponse> => {
  return fetch(BASE_URL + "/user/login", {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: JSON.stringify(login),
  })
    .then((response) => response.json())
    .then((loginResponse) => loginResponse)
    .catch((e) => {
      console.error(e);
    });
};

export const registerUser = (
  register: RegisterRequest
): Promise<RegisterResponse> => {
  return fetch(BASE_URL + "/user/create", {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: JSON.stringify(register),
  })
    .then((response) => response.json())
    .then((registerResponse) => registerResponse)
    .catch((e) => {
      console.error(e);
    });
};

export const setRememberMe = (sessionToken: string): Promise<UserResponse> => {
  return fetch(BASE_URL + "/user/rememberOnboarding", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "X-ACCESS-TOKEN": sessionToken,
    },
  })
    .then((response) => response.json())
    .then((remembermeResponse) => remembermeResponse);
};
