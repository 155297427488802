import React, { useContext } from "react";
import { Loading } from "../molecules/Loading";
import { ContextType, SpeeltuinchefContext } from "../../store/Store";
import { deleteUserSession } from "../../api/user/UserCalls";
import { getSessionToken, revokeSession } from "../../helpers/SessionHelper";

export const Logout = () => {
  const context = useContext(SpeeltuinchefContext);
  logoutUserFromApi(context);

  return <Loading />;
};

const logoutUserFromApi = async (context: ContextType) => {
  try {
    const response = await deleteUserSession(getSessionToken(context));

    if (response) {
      revokeSession();
    }
  } catch (e) {
    console.error(e);
  }
};
