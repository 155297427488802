import React, { useContext, useState } from "react";
import { postUpdateUserAddress } from "../../api/user/UserCalls";
import { SpeeltuinchefContext } from "../../store/Store";
import {
  getSessionToken,
  playgroundsFromApiToState,
} from "../../helpers/SessionHelper";

export const AddressForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [addition, setAddition] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const context = useContext(SpeeltuinchefContext);
  const { dispatch } = context;

  const handleAddress = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isValidPostalCode() || !isValidHousnumber()) {
      setErrorMessage("Vul tenminste een geldige postcode en huisnummer in.");
      setIsLoading(false);
      return;
    }

    try {
      const address = {
        housenumber: housenumber,
        postalCode: postalCode.replace(/\s/g, ""),
        addition: addition === "" ? null : addition,
      };
      const response = await postUpdateUserAddress(
        address,
        getSessionToken(context)
      );
      if (response.status === 400) {
        setErrorMessage(
          "Het ingevulde adres is al aan een ander account gekoppeld. Neem bij vragen contact met ons op via " +
            process.env.REACT_APP_CLIENT_CONTACT_EMAIL +
            "."
        );
        setIsLoading(false);
      } else {
        dispatch({ type: "SET_USER_ADDRESS", payload: address });
        await playgroundsFromApiToState(context);
      }
      //move to next screen
    } catch (e) {
      setIsLoading(false);
    }
  };

  const isValidPostalCode = (): boolean => {
    return postalCode.replace(/\s/g, "").length === 6;
  };

  const isValidHousnumber = (): boolean => {
    return housenumber.trim().length > 0;
  };

  const errorComponent =
    errorMessage !== "" ? (
      <div className={"message message--warning"} role={"alert"}>
        {errorMessage}
      </div>
    ) : null;

  return (
    <div className={"addressForm block"}>
      <h1>Adresgegevens</h1>
      <p>
        Voor het gebruik van Speeltuinchef hebben wij uw adresgegevens nodig.
        Met deze gegevens kunnen wij u speeltuinen in uw omgeving laten zien
        waarop u inspraak kunt hebben.
      </p>

      {errorComponent}

      <form onSubmit={handleAddress}>
        <input
          type={"text"}
          placeholder={"Uw postcode"}
          aria-label={"Uw postcode"}
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          required
          autoComplete={"postal-code"}
        />
        <input
          type={"text"}
          placeholder={"Uw huisnummer"}
          aria-label={"Uw huisnummer"}
          value={housenumber}
          onChange={(e) => setHousenumber(e.target.value)}
          required
          autoComplete={"address-line1"}
        />
        <input
          type={"text"}
          placeholder={"Toevoeging (optioneel)"}
          value={addition}
          onChange={(e) => setAddition(e.target.value)}
          aria-label={"Huisnummer toevoeging"}
          autoComplete={"address-line2"}
        />

        <button className={"button"} aria-label={"Bevestig uw adres"}>
          {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Opslaan"}
        </button>
      </form>
    </div>
  );
};
