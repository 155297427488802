import React from "react";

export type FamilyMemberCounterProps = {
  currentValue: number;
  increaseFunction: () => void;
  decreaseFunction: () => void;
  title: string;
};

export const FamilyMemberCounter = (props: FamilyMemberCounterProps) => {
  return (
    <div className={"familyMemberCounter"}>
      <div className={"familyMemberCounter__change"}>
        <button
          onClick={() => {
            props.decreaseFunction();
          }}
          disabled={props.currentValue === 0}
          aria-label={"Minnetje, verlaag aantal " + props.title}
        >
          -
        </button>
      </div>
      <div className={"familyMemberCounter__value"} role={"status"}>
        {props.currentValue}
      </div>
      <div className={"familyMemberCounter__change"}>
        <button
          onClick={() => {
            props.increaseFunction();
          }}
          aria-label={"Plusje, verhoog aantal " + props.title}
        >
          +
        </button>
      </div>
    </div>
  );
};
