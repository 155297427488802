import React from "react";
import { Link } from "react-router-dom";

export type TileProps = {
  imageUrl: string;
  imageDescription: string;
  title: string;
  isFinished: boolean;
  linkUrl?: string;
};

export const Tile = (props: TileProps) => {
  const tile = (
    <div className={"tile"}>
      <div className={"tile__image"}>
        <img
          src={props.imageUrl}
          alt={props.imageDescription}
          aria-hidden={true}
        />
      </div>
      <div className={"tile__title"}>
        <div className={"tile__title__h2"}>
          {props.isFinished ? (
            <img src={"/assets/images/check.svg"} alt={"Afgerond"} />
          ) : null}
          <span dangerouslySetInnerHTML={{ __html: props.title }}></span>
        </div>
      </div>
    </div>
  );

  if (props.linkUrl) {
    return <Link to={props.linkUrl}>{tile}</Link>;
  }
  return tile;
};
