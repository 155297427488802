import React from "react";
import "./App.css";
import SpeeltuinchefRoute from "./route/SpeeltuinchefRoute";

import "./styles/_main.scss";
import { SpeeltuinProvider } from "./store/Store";

function App() {
  return (
    <SpeeltuinProvider>
      <SpeeltuinchefRoute />
    </SpeeltuinProvider>
  );
}

export default App;
