import React, { useContext, useState } from "react";
import { PlaygroundType } from "../../models/PlaygroundType";
import BottomPaginationBar from "./BottomPaginationBar";
import { Image } from "../atoms/Image";
import { CheckBox } from "../atoms/Checkbox";
import { updateVote } from "../../api/playgrounds/PlaygroundsCalls";
import { SpeeltuinchefContext } from "../../store/Store";
import { useNavigate } from "react-router-dom";

export type ConceptSelectProps = {
  playground: PlaygroundType;
};

export const ConceptSelect = (props: ConceptSelectProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const defaultConcept = props.playground.shoppingList.votedConcept
    ? props.playground.shoppingList.votedConcept
    : 0;

  const defaultRemark = props.playground.shoppingList.votedConceptRemark
    ? props.playground.shoppingList.votedConceptRemark
    : "";

  const [remark, setRemark] = useState(defaultRemark);
  const [selectedConcept, setSelectedConcept] = useState(defaultConcept);

  const context = useContext(SpeeltuinchefContext);
  const navigate = useNavigate();

  const getConcept = () => {
    const concept = props.playground.concepts[currentPage];
    return (
      <div>
        <h2>{concept.title}</h2>
        <p>{concept.description}</p>
        {concept.images.map((image) => {
          return (
            <div key={image.id} className={"conceptSelect__image"}>
              <Image image={image} />
              <p>
                <a
                  download
                  href={
                    image.base64 ? image.base64 : image.url ? image.url : ""
                  }
                  target={"_blank"}
                  aria-label={"Download grote versie van afbeelding"}
                  rel={"noreferrer"}
                >
                  Download grote versie
                </a>
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const selectConcept = (id: number) => {
    setSelectedConcept(id);
  };

  const saveAndContinue = async () => {
    const { state } = context;
    if (state.session.token === null) {
      location.href = "/login";
      return;
    }
    setIsLoading(true);

    props.playground.shoppingList.votedConcept = selectedConcept;
    props.playground.shoppingList.votedConceptRemark = remark;

    await updateVote(
      state.session.token,
      props.playground,
      props.playground.shoppingList
    );
    navigate("/playground/" + props.playground.id);
  };

  const getVoting = () => {
    return (
      <div>
        <h2>Stemmen!</h2>
        {props.playground.concepts.map((concept) => {
          return (
            <div key={concept.id} className={"conceptSelect__vote"}>
              <label>
                <div
                  className={
                    selectedConcept === concept.id
                      ? "conceptSelect__vote-title conceptSelect__vote-selected"
                      : "conceptSelect__vote-title"
                  }
                >
                  <span>{concept.title}</span>
                </div>
                <CheckBox
                  ariaText={"Selecteer ontwerp " + concept.title}
                  checked={selectedConcept === concept.id}
                  id={concept.id}
                  onChangeVoteFunction={selectConcept}
                  useHeart={true}
                />
              </label>
            </div>
          );
        })}
        <div className={"conceptSelect__remark"}>
          <fieldset>
            <label htmlFor={"remark"}>
              Wat vindt u positief aan het ontwerp? Is er iets aan het ontwerp
              dat u liever anders ziet, of heeft u nog andere opmerkingen op het
              ontwerp?
            </label>
            <textarea
              value={remark}
              name={"remark"}
              id="remark"
              onChange={(event) => setRemark(event.target.value)}
              placeholder={"Optionele opmerking bij het gekozen ontwerp"}
            />
          </fieldset>
        </div>
        <div className={"conceptSelect__action"}>
          <button className={"button"} onClick={saveAndContinue}>
            {isLoading ? (
              <img src={"/assets/images/loader.svg"} />
            ) : (
              <span>Stem!</span>
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={"block conceptSelect"}>
        <h1>{props.playground.name}</h1>
        {currentPage === props.playground.concepts.length
          ? getVoting()
          : getConcept()}
      </div>{" "}
      <BottomPaginationBar
        count={props.playground.concepts.length + 1}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        hasVoting={false}
      />
    </div>
  );
};
