import React, { useEffect, useState } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { TileExplanation } from "../components/molecules/TileExplanation";
import { QuizContainer } from "../components/organisms/QuizContainer";

export const PlaygroundQuizPage = () => {
  const [seenDescription, setSeenDescription] = useState(false);

  useEffect(() => {
    document.title = "Quiz - Speeltuinchef";
  });

  if (!seenDescription) {
    return (
      <div>
        <NavigationBar />
        <main>
          <TileExplanation
            imageUrl={"/assets/images/quiz_tile.svg"}
            imageDescription={""}
            title={"Quiz"}
            text={
              "Gewoon voor de leuk of om jezelf te verbazen; de quiz die je vertelt wat voor soort speeltuinbelever jij bent. Je kan de quiz zo vaak spelen als je wilt. Ook nadat je je boodschappenlijstje ingestuurd hebt; de resultaten zijn voor jullie zelf. Veel plezier!"
            }
            setSeenDescription={setSeenDescription}
          />
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <NavigationBar />
        <main>
          <QuizContainer />
        </main>
      </div>
    );
  }
};
