import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "../page/LoginPage";
import HomePage from "../page/HomePage";
import React, { useContext, useEffect, useState } from "react";
import { SpeeltuinchefContext } from "../store/Store";
import { checkAndLoadState, hasValidSession } from "../helpers/SessionHelper";
import { PlaygroundsPage } from "../page/PlaygroundsPage";
import { Loading } from "../components/molecules/Loading";
import { PersonalPage } from "../page/PersonalPage";
import { Logout } from "../components/organisms/Logout";
import { PlaygroundPage } from "../page/PlaygroundPage";
import { PlaygroundIngredientsPage } from "../page/PlaygroundIngredientsPage";
import { PlaygroundFamilyPage } from "../page/PlaygroundFamilyPage";
import { PlaygroundMoodboardPage } from "../page/PlaygroundMoodboardPage";
import { PlaygroundQuizPage } from "../page/PlaygroundQuizPage";
import { PlaygroundRemarkPage } from "../page/PlaygroundRemarkPage";
import { RegisterPage } from "../page/RegisterPage";
import { TermsPage } from "../page/TermsPage";
import { PasswordResetPage } from "../page/PasswordResetPage";
import { PlaygroundActivitiesPage } from "../page/PlaygroundActivitiesPage";
import { PlaygroundQuestionnairePage } from "../page/PlaygroundQuestionnairePage";
import { PlaygroundShoppinglistPage } from "../page/PlaygroundShoppinglistPage";
import { PlaygroundConceptsPage } from "../page/PlaygroundConceptsPage";
import SitemapPage from "../page/SitemapPage";

export default function SpeeltuinchefRoute() {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const [isLoadingState, setIsLoadingState] = useState(false);

  useEffect(() => {
    if (!isLoadingState && hasValidSession(context)) {
      setIsLoadingState(true);
      checkAndLoadState(context);
    }
  });

  const routes = hasValidSession(context)
    ? state.user === undefined || state.user.email === null
      ? LoadingRoute()
      : LoggedInRoute()
    : OpenRoute();

  return <BrowserRouter>{routes}</BrowserRouter>;
}

export const OpenRoute = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route
        path="/password-forgot/:resetToken"
        element={<PasswordResetPage />}
      />
      <Route path="/password-forgot" element={<PasswordResetPage />} />
      <Route path="/sitemap" element={<SitemapPage />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
};

export const LoggedInRoute = () => {
  return (
    <Routes>
      <Route path="/personal" element={<PersonalPage />} />
      <Route path="/playgrounds" element={<PlaygroundsPage />} />
      <Route
        path="/playground/:playgroundId/ingredients"
        element={<PlaygroundIngredientsPage />}
      />
      <Route
        path="/playground/:playgroundId/family"
        element={<PlaygroundFamilyPage />}
      />
      <Route
        path="/playground/:playgroundId/quiz"
        element={<PlaygroundQuizPage />}
      />
      <Route
        path="/playground/:playgroundId/remark"
        element={<PlaygroundRemarkPage />}
      />
      <Route
        path="/playground/:playgroundId/moodboard"
        element={<PlaygroundMoodboardPage />}
      />
      <Route
        path={"/playground/:playgroundId/activity"}
        element={<PlaygroundActivitiesPage />}
      />
      <Route
        path={"/playground/:playgroundId/questionnaire"}
        element={<PlaygroundQuestionnairePage />}
      />
      <Route
        path={"/playground/:playgroundId/shopping-list"}
        element={<PlaygroundShoppinglistPage />}
      />
      <Route
        path={"/playground/:playgroundId/concepts"}
        element={<PlaygroundConceptsPage />}
      />
      <Route path="/playground/:playgroundId" element={<PlaygroundPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/sitemap" element={<SitemapPage />} />
      <Route path="/" element={<PlaygroundsPage />} />
    </Routes>
  );
};

export const LoadingRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Loading />} />
    </Routes>
  );
};
