import React from "react";

export interface SkipOnboardingProps {
  skipFunction: () => void;
}

export const SkipOnboarding = (props: SkipOnboardingProps) => {
  return (
    <button
      className={"button button-secondary onboarding__skipbutton"}
      aria-label={"Sla instructie over"}
      onClick={() => {
        props.skipFunction();
      }}
    >
      Sla over
    </button>
  );
};

export default SkipOnboarding;
