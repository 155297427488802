import React, { useEffect, useState } from "react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { getCurrentPoints } from "../../helpers/ShoppinglistHelper";

export type IngredientsPointsIndicatorType = {
  playground: PlaygroundType;
};

export const IngredientsPointsIndicator = (
  props: IngredientsPointsIndicatorType
) => {
  const maxPoints = props.playground.maxIngredientPoints;
  const currentPoints = getCurrentPoints(props.playground);
  const prct =
    currentPoints / maxPoints > 1
      ? 100
      : Math.round((currentPoints / maxPoints) * 100);

  let indicatorModifier;
  if (prct > 90) {
    indicatorModifier = "-full";
  } else if (prct > 60) {
    indicatorModifier = "-warning";
  } else {
    indicatorModifier = "-good";
  }

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollTop = window.scrollY;
      if (scrollTop > 75 && !isSticky) {
        setIsSticky(true);
      } else if (scrollTop <= 75 && isSticky) {
        setIsSticky(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const className = isSticky
    ? "ingredientsPointsIndicator ingredientsPointsIndicator__sticky"
    : "ingredientsPointsIndicator";

  return (
    <div className={className}>
      <div
        className={
          "ingredientsPointsIndicator__indicator ingredientsPointsIndicator__indicator" +
          indicatorModifier
        }
      >
        <span role={"status"}>Speelruimte {prct}%</span>
        <div
          className={"ingredientsPointsIndicator__background"}
          style={{ width: prct + "%" }}
        />
      </div>
    </div>
  );
};
