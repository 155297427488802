import React, { KeyboardEvent, useState } from "react";
import { Dispatch, SetStateAction } from "react";
// @ts-ignore
import _uniqueId from "lodash/uniqueId";

export interface CheckBoxProps {
  text?: string;
  textElement?: React.ReactNode;
  ariaText: string;
  checked: boolean;
  onChangeSetStateFunction?: Dispatch<SetStateAction<boolean>>;
  onChangeQuestionnaireFunction?: (answer: string) => void;
  onChangeVoteFunction?: (id: number) => void;
  id?: number;
  useStar?: boolean;
  useHeart?: boolean;
  checkboxId?: string;
}

export const CheckBox = (props: CheckBoxProps) => {
  const [id] = useState(_uniqueId("checkbox-"));

  const catchSpacebarPress = (e: KeyboardEvent) => {
    if (e.key === " ") {
      e.preventDefault();
      if (props.onChangeSetStateFunction) {
        props.onChangeSetStateFunction(!props.checked);
      }
      if (props.onChangeQuestionnaireFunction && props.text) {
        props.onChangeQuestionnaireFunction(props.text);
      }
      if (props.onChangeVoteFunction && props.id) {
        props.onChangeVoteFunction(props.id);
      }
    }
  };
  return (
    <div className={"checkbox"}>
      <label
        className={"checkbox__label"}
        onKeyPress={catchSpacebarPress}
        tabIndex={0}
      >
        <input
          type={"checkbox"}
          tabIndex={-1}
          id={props.checkboxId ? props.checkboxId : id}
          checked={props.checked}
          onChange={(e) => {
            if (props.onChangeSetStateFunction) {
              props.onChangeSetStateFunction(e.target.checked);
            }
            if (props.onChangeQuestionnaireFunction && props.text) {
              props.onChangeQuestionnaireFunction(props.text);
            }
            if (props.onChangeVoteFunction && props.id) {
              props.onChangeVoteFunction(props.id);
            }
          }}
        />
        <img
          className={"checkbox-selected checkbox__image"}
          src={
            props.useStar
              ? "/assets/images/star-active.svg"
              : props.useHeart
              ? "/assets/images/heart-filled.svg"
              : "/assets/images/checkbox_checked.svg"
          }
          alt={props.ariaText + " geselecteerd"}
        />
        <img
          className={"checkbox-empty checkbox__image"}
          src={
            props.useStar
              ? "/assets/images/star-passive.svg"
              : props.useHeart
              ? "/assets/images/heart-empty.svg"
              : "/assets/images/checkbox_empty.svg"
          }
          alt={props.ariaText + " niet geselecteerd"}
        />
        {props.text || props.textElement ? (
          <label htmlFor={props.checkboxId ? props.checkboxId : id}>
            {props.text}
            {props.textElement}
          </label>
        ) : null}
      </label>
    </div>
  );
};
