import React from "react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { Image } from "../atoms/Image";
import { FamiliyMemberType } from "../../models/FamiliyMemberType";

export type ShoppingListReviewProps = {
  playground: PlaygroundType;
};

export const ShoppingListReview = ({ playground }: ShoppingListReviewProps) => {
  const getSelectedMoodboards = (): React.ReactNode => {
    const selectedMoodboards = playground.moodboards.filter((moodboard) => {
      return playground.shoppingList.moodboards.includes(moodboard.id);
    });

    return selectedMoodboards.map((moodboard) => {
      return (
        <div key={"moodboard" + moodboard.id} className={"option"}>
          <Image image={moodboard.image} /> <h3>{moodboard.title}</h3>
        </div>
      );
    });
  };

  const getSelectedActivities = (): React.ReactNode => {
    const selectedActivities = playground.activities.filter((activity) => {
      return playground.shoppingList.activities.includes(activity.id);
    });

    return selectedActivities.map((activity) => {
      return (
        <div key={"activity" + activity.id} className={"option"}>
          <Image image={activity.image} /> <h3>{activity.title}</h3>
        </div>
      );
    });
  };

  const getSelectedIngredients = (): React.ReactNode => {
    const selectedIngredients = playground.ingredients.filter((ingredient) => {
      return playground.shoppingList.ingredients.includes(ingredient.id);
    });

    return selectedIngredients.map((ingredient) => {
      return (
        <div key={"ingredient" + ingredient.id} className={"option"}>
          <Image image={ingredient.image} /> <h3>{ingredient.title}</h3>
        </div>
      );
    });
  };

  const getFamily = (): React.ReactNode => {
    return playground.shoppingList.family.map((family) => {
      let familyItem: FamiliyMemberType = playground.family[0];
      playground.family.forEach((familyMember) => {
        if (familyMember.id === family.id) {
          familyItem = familyMember;
        }
      });

      if (familyItem && family.count > 0) {
        return (
          <div key={"family" + familyItem.id} className={"option"}>
            <Image image={familyItem.image} />{" "}
            <h3>
              {family.count}x {familyItem.title}
            </h3>
          </div>
        );
      }
    });
  };

  return (
    <div className={"block shoppingListReview"}>
      <h1>{playground.name}</h1>
      <div className={"message message--warning"} role={"alert"}>
        Let op, na het insturen kunt u geen wijzigingen meer aanbrengen aan uw
        lijstje!
      </div>
      <div className={"shoppingListReview__item"}>
        <div className={"shoppingListReview__item-title"}>
          <img
            src={"/assets/images/checkbox_checked.svg"}
            alt={"Aangevinkt invulvakje"}
          />
          <h2>Jullie smaak</h2>
        </div>
        {getSelectedMoodboards()}
      </div>
      {playground.hasActivity && (
        <>
          <div className={"shoppingListReview__item"}>
            <div className={"shoppingListReview__item-title"}>
              <img
                src={"/assets/images/checkbox_checked.svg"}
                alt={"Aangevinkt invulvakje"}
              />
              <h2>Jullie activiteiten</h2>
            </div>
            {getSelectedActivities()}
          </div>
        </>
      )}
      <div className={"shoppingListReview__item"}>
        <div className={"shoppingListReview__item-title"}>
          <img
            src={"/assets/images/checkbox_checked.svg"}
            alt={"Aangevinkt invulvakje"}
          />
          <h2>Jullie ingrediënten</h2>
        </div>
        <div className={"shoppingListReview__item-multicolumn"}>
          {getSelectedIngredients()}
        </div>
      </div>
      {playground.hasFamily && (
        <>
          <div className={"shoppingListReview__item"}>
            <div className={"shoppingListReview__item-title"}>
              <img
                src={"/assets/images/checkbox_checked.svg"}
                alt={"Aangevinkt invulvakje"}
              />
              <h2>Jullie thuis</h2>
            </div>
            <div className={"shoppingListReview__item-multicolumn family"}>
              {getFamily()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
