import React, { Dispatch, SetStateAction } from "react";

export type ChefWarningProps = {
  text: string;
  closeFunction: Dispatch<SetStateAction<boolean>>;
};

export const ChefWarning = (props: ChefWarningProps) => {
  return (
    <div className={"chefWarning"}>
      <div className={"chefWarning__container"}>
        <div className={"chefWarning__text"} role={"alert"}>
          <p>{props.text}</p>
          <p>
            <button
              className={"button"}
              onClick={() => {
                props.closeFunction(false);
              }}
            >
              Ok
            </button>
          </p>
        </div>
        <div className={"chefWarning__image"}>
          <img
            src={"/assets/images/chef_big_empty.png"}
            alt={
              "Chef die ingrediënten in een pan gooit terwijl hij een waarschuwing uitspreekt"
            }
          />
        </div>
      </div>
    </div>
  );
};
