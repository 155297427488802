import React from "react";

export type BottomActionBarProps = {
  text: string;
  isLoading: boolean;
  clickFunction: () => void;
};

export const BottomActionBar = (props: BottomActionBarProps) => {
  return (
    <div className={"bottomActionBar"}>
      <button
        className={"button"}
        onClick={() => {
          props.clickFunction();
        }}
      >
        {props.isLoading ? (
          <img src={"/assets/images/loader.svg"} />
        ) : (
          props.text
        )}
      </button>
    </div>
  );
};
