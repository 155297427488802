import React, { useContext, useEffect } from "react";
import { SpeeltuinchefContext } from "../store/Store";
import { getCorrectPlaygroundsComponent } from "../helpers/RouteHelper";
import NavigationBar from "../components/organisms/NavigationBar";

export const PlaygroundsPage = () => {
  const context = useContext(SpeeltuinchefContext);

  useEffect(() => {
    document.title = "Mijn speeltuinen - Speeltuinchef";
  });

  const component = getCorrectPlaygroundsComponent(context);
  return (
    <div>
      <NavigationBar />
      <main>{component}</main>
    </div>
  );
};
