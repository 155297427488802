import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import NavigationBar from "../components/organisms/NavigationBar";
import { PlaygroundDashboard } from "../components/organisms/PlaygroundDashboard";

export const PlaygroundPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();

  useEffect(() => {
    document.title = "Jullie speeltuin - Speeltuinchef";
  });

  return (
    <div>
      <NavigationBar />
      <main>
        <PlaygroundDashboard playgroundId={parseInt(playgroundId ?? '')} />
        <div className={"block"}>
          <Link to={"/sitemap"} arial-label={"Bezoek de sitemap"}>
            Sitemap
          </Link>
        </div>
      </main>
    </div>
  );
};
