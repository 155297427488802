import { AddressType } from "../../models/AddressType";

export type UserState = {
  email: string | null;
  roles: Array<string>;
  address: AddressType | null;
  hasSeenOnboarding: boolean;
};

export const initialUserState: UserState = {
  email: null,
  roles: [],
  address: null,
  hasSeenOnboarding: false,
};
