import React, { useEffect } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { Link } from "react-router-dom";

export const HomePage = () => {
  useEffect(() => {
    document.title = "Speeltuinchef";
  });

  return (
    <div>
      <NavigationBar />
      <main>
        <div className={"homepage"}>
          <div className={"homepage__logo"}>
            <img
              src="/assets/images/chef_big.png"
              alt="De speeltuinchef. Speeltuinen koken met jouw ingrediënten! Chefkok die speeltoestellen in grote pan roert. Tijdens het roeren zegt hij 'Lekker hoor!' en vraagt hij 'Weet jij nog wat lekkere ingrediënten?'"
            />
          </div>
          <div className={"homepage__actions"}>
            <Link to="/register" className={"button"}>
              Ik ben nieuw hier
            </Link>
            <Link to="/login" className={"button"}>
              Inloggen
            </Link>
          </div>
          <div className={"sitemap__link"}>
            <Link to={"/sitemap"} arial-label={"Bezoek de sitemap"}>
              Sitemap
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;
