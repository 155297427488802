import React from "react";
import { MoodboardType } from "../../models/MoodboardType";
import { Image } from "../atoms/Image";

export type MoodboardProps = {
  moodboard: MoodboardType;
};

export const Moodboard = (props: MoodboardProps) => {
  return (
    <div className={"moodboard"}>
      <h2>{props.moodboard.title}</h2>
      {props.moodboard.description ? (
        <p>{props.moodboard.description}</p>
      ) : null}
      <div className={"moodboard__desktop-image"}>
        <Image image={props.moodboard.image} />
      </div>
      <div className={"moodboard__mobile-image"}>
        <Image image={props.moodboard.imageMobile} />
      </div>
    </div>
  );
};
