import React, { useContext } from "react";
import { IngredientsPointsIndicator } from "./IngredientsPointsIndicator";
import { SpeeltuinchefContext } from "../../store/Store";
import { getPlaygroundById } from "../../helpers/PlaygroundHelper";
import { IngredientSelect } from "./IngredientsSelect";

export type IngredientsContainerType = {
  playgroundId: number;
};

export const IngredientsContainer = (props: IngredientsContainerType) => {
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(props.playgroundId, context);

  if (!playground) {
    return <div></div>;
  }

  return (
    <div className={"block block-topmargin"}>
      <h1>{playground.name}</h1>
      <IngredientsPointsIndicator playground={playground} />
      <IngredientSelect playground={playground} />
    </div>
  );
};
