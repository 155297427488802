import React, { useEffect, useState } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { TileExplanation } from "../components/molecules/TileExplanation";
import { IngredientsContainer } from "../components/organisms/IngredientsContainer";
import { useParams } from "react-router-dom";

export const PlaygroundIngredientsPage = () => {
  const [seenDescription, setSeenDescription] = useState(false);
  const { playgroundId } = useParams<{ playgroundId: string }>();

  useEffect(() => {
    document.title = "Jullie ingrediënten - Speeltuinchef";
  });

  if (!seenDescription) {
    return (
      <div>
        <NavigationBar />
        <main>
          <TileExplanation
            imageUrl={"/assets/images/ingredient_tile.svg"}
            imageDescription={""}
            title={"Jullie ingrediënten"}
            text={
              "Wat wil je graag terugzien in je speeltuin? Let op: inrichten kost geld! Op de bovenste balk zie je of je nog ruimte hebt, wisselen kan altijd. Wil je iets echt heel graag? Je kan 1 speeltoestel een ster geven en daarmee tot favoriet maken! Veel plezier!"
            }
            setSeenDescription={setSeenDescription}
          />
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <NavigationBar />
        <main>
          <IngredientsContainer playgroundId={parseInt(playgroundId ?? '')} />
        </main>
      </div>
    );
  }
};
