import React, { useContext, useEffect } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { SpeeltuinchefContext } from "../store/Store";
import { Link } from "react-router-dom";
import { getPlaygroundSiteMap } from "../helpers/PlaygroundHelper";

export const SitemapPage = () => {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;

  useEffect(() => {
    document.title = "Sitemap - Speeltuinchef";
  });

  const loggedInSitemap = (): React.ReactNode => {
    return (
      <div>
        <ul>
          <li>
            <Link to={"/playgrounds"} aria-label={"Overzicht speeltuinen"}>
              Mijn speeltuinen
            </Link>
          </li>
          <li>
            <Link to={"/personal"} aria-label={"Persoonlijke gegevens pagina"}>
              Persoonlijke gegevens
            </Link>
          </li>
          <li>
            <Link to={"/logout"} aria-label={"Uitlog pagina"}>
              Uitloggen
            </Link>
          </li>
          {getPlaygroundsSiteMap()}
        </ul>
      </div>
    );
  };

  const getPlaygroundsSiteMap = (): React.ReactNode => {
    if (state.playgrounds === null) {
      return <div />;
    }
    return state.playgrounds.map((playground) => {
      return (
        <li key={playground.id}>
          <Link
            to={"/playground/" + playground.id}
            aria-label={"Overzichts pagina " + playground.name}
          >
            {playground.name}
            <ul>{getPlaygroundSiteMap(playground)}</ul>
          </Link>
        </li>
      );
    });
  };

  const openSiteMap = (): React.ReactNode => {
    return (
      <div>
        <ul>
          <li>
            <Link to={"/"} aria-label={"Bezoek de homepage"}>
              Homepage
            </Link>
          </li>
          <li>
            <Link to={"/register"} aria-label={"Bezoek de registreer pagina"}>
              Registreren
            </Link>
          </li>
          <li>
            <Link to={"/login"} aria-label={"Bezoek de inlog pagina"}>
              Inloggen
            </Link>
          </li>
          <li>
            <Link
              to={"/password-forgot"}
              aria-label={"Bezoek de wachtwoord vergeten pagina"}
            >
              Wachtwoord vergeten
            </Link>
          </li>
          <li>
            <Link to={"/terms"} aria-label={"Bezoek de algemene voorwaarden"}>
              Algemene voorwaarden
            </Link>
          </li>
          <li>
            <a
              href={"https://www.toegankelijkheidsverklaring.nl/register/5032"}
              aria-label={"Bezoek de toegankelijkheidsverklaring"}
              target={"_blank"}
              rel="noreferrer"
            >
              Toegankelijkheidsverklaring
            </a>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div>
      <NavigationBar />
      <main>
        <div className={"block sitemap"}>
          <h1>Sitemap</h1>
          {state.session.token ? loggedInSitemap() : openSiteMap()}
        </div>
      </main>
    </div>
  );
};

export default SitemapPage;
