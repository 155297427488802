import React from "react";

export const Loading = () => {
  return (
    <div className={"pageLoader"} role={"status"}>
      <img
        src={"/assets/images/logo_speeltuinchef.png"}
        alt={"Speeltuinchef wordt geladen"}
      />
      <p>Laden</p>
    </div>
  );
};
