import React, { useEffect } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { PersonalOverview } from "../components/organisms/PersonalOverview";

export const PersonalPage = () => {
  useEffect(() => {
    document.title = "Persoonlijke gegevens - Speeltuinchef";
  });

  return (
    <div>
      <NavigationBar />
      <main>
        <PersonalOverview />
      </main>
    </div>
  );
};
