import React, { useContext, useState } from "react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { IngredientTile } from "../molecules/IngredientTile";
import { SpeeltuinchefContext } from "../../store/Store";
import {
  getCurrentPoints,
  setFavoriteIngredient,
  toggleIngredientInShoppingList,
} from "../../helpers/ShoppinglistHelper";
import { IngredientType } from "../../models/IngredientType";
import { ChefWarning } from "../molecules/ChefWarning";
import { BottomActionBar } from "./BottomActionBar";
import { updateShoppingList } from "../../api/playgrounds/PlaygroundsCalls";
import { useNavigate } from "react-router-dom";

export type IngredientsSelectProps = {
  playground: PlaygroundType;
};
export const IngredientSelect = (props: IngredientsSelectProps) => {
  const context = useContext(SpeeltuinchefContext);
  const navigate = useNavigate();
  const [hasTooMuchPointsWarning, setHasTooMuchPointsWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectIngredient = (ingredient: IngredientType) => {
    if (
      getCurrentPoints(props.playground) + ingredient.points >
        props.playground.maxIngredientPoints &&
      !props.playground.shoppingList.ingredients.includes(ingredient.id)
    ) {
      setHasTooMuchPointsWarning(true);
    } else {
      toggleIngredientInShoppingList(ingredient, props.playground, context);
    }
  };
  const favoriteIngredient = (ingredient: IngredientType) => {
    setFavoriteIngredient(ingredient, props.playground, context);
  };

  const saveAndContinue = async () => {
    const { state } = context;
    if (state.session.token === null) {
      location.href = "/login";
      return;
    }
    setIsLoading(true);
    await updateShoppingList(
      state.session.token,
      props.playground,
      props.playground.shoppingList
    );
    navigate("/playground/" + props.playground.id);
  };

  const ingredients = getRenderedIngredients(
    props.playground,
    selectIngredient,
    favoriteIngredient
  );

  const tooMuchPointsWarning = hasTooMuchPointsWarning ? (
    <ChefWarning
      text={"Ik heb niet genoeg geld voor al deze ingrediënten!"}
      closeFunction={setHasTooMuchPointsWarning}
    />
  ) : null;

  return (
    <div>
      {tooMuchPointsWarning}
      {ingredients}
      <BottomActionBar
        text={"Verder"}
        isLoading={isLoading}
        clickFunction={saveAndContinue}
      />
    </div>
  );
};

const getIngredientCategories = (playground: PlaygroundType): Array<string> => {
  const categories: Array<string> = [];

  playground.ingredients.forEach((ingredient) => {
    if (!categories.includes(ingredient.category)) {
      categories.push(ingredient.category);
    }
  });

  return categories;
};

const getRenderedIngredients = (
  playground: PlaygroundType,
  selectIngredient: (ingredient: IngredientType) => void,
  favoriteIngredient: (ingredient: IngredientType) => void
) => {
  const categories = getIngredientCategories(playground);

  return categories.map((category) => {
    const ingredientsForCategory = playground.ingredients.filter(
      (ingredient) => {
        return ingredient.category === category;
      }
    );
    const ingredients = ingredientsForCategory.map((ingredient) => {
      return (
        <IngredientTile
          key={ingredient.id}
          ingredient={ingredient}
          playground={playground}
          selectIngredient={selectIngredient}
          favoriteIngredient={favoriteIngredient}
        />
      );
    });

    return (
      <div key={category}>
        <h2>{category}</h2>
        <div className={"playgroundDashboard"}>{ingredients}</div>{" "}
      </div>
    );
  });
};
