import React, { useContext, useEffect, useState } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { ShoppingListReview } from "../components/organisms/ShoppingListReview";
import { useNavigate, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import { BottomActionBar } from "../components/organisms/BottomActionBar";
import { updateShoppingList } from "../api/playgrounds/PlaygroundsCalls";

export const PlaygroundShoppinglistPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId ?? ''), context);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Jullie boodschappenlijstje - Speeltuinchef";
  });

  if (playground === null) {
    return <div role={"status"}>Laden..</div>;
  }

  const saveAndContinue = async () => {
    const { state } = context;
    if (state.session.token === null) {
      location.href = "/login";
      return;
    }
    setIsLoading(true);

    playground.shoppingList.isFinalized = true;

    await updateShoppingList(
      state.session.token,
      playground,
      playground.shoppingList
    );
    navigate("/playground/" + playground.id);
  };

  return (
    <div>
      <NavigationBar />
      <main>
        <ShoppingListReview playground={playground} />
        <BottomActionBar
          text={"Boodschappenlijstje insturen"}
          isLoading={isLoading}
          clickFunction={saveAndContinue}
        />
      </main>
    </div>
  );
};
