import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavigationBar from "../components/organisms/NavigationBar";
import { TileExplanation } from "../components/molecules/TileExplanation";
import { FamilyContainer } from "../components/organisms/FamilyContainer";

export const PlaygroundFamilyPage = () => {
  const [seenDescription, setSeenDescription] = useState(false);
  const { playgroundId } = useParams<{ playgroundId: string }>();

  useEffect(() => {
    document.title = "Jullie thuis - Speeltuinchef";
  });

  if (!seenDescription) {
    return (
      <div>
        <NavigationBar />
        <main>
          <TileExplanation
            imageUrl={"/assets/images/family_tile.svg"}
            imageDescription={""}
            title={"Jullie thuis"}
            text={
              "Wie wonen er allemaal bij je thuis? Heb je 3 katten of 7 babybroertjes? Gebruik de plusjes en minnetjes om het juiste aantal aan te geven. Veel plezier!"
            }
            setSeenDescription={setSeenDescription}
          />
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <NavigationBar />
        <main>
          <FamilyContainer playgroundId={parseInt(playgroundId ?? '')} />
        </main>
      </div>
    );
  }
};
