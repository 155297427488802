import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import NavigationBar from "../components/organisms/NavigationBar";
import { TileExplanation } from "../components/molecules/TileExplanation";
import { QuestionList } from "../components/organisms/QuestionList";

export const PlaygroundQuestionnairePage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId ?? ''), context);

  const [seenDescription, setSeenDescription] = useState(false);

  useEffect(() => {
    document.title = "Enquête - Speeltuinchef";
  });

  if (playground === null) {
    return <div role={"status"}>Laden..</div>;
  } else if (playground.questions.length === 0) {
    location.href = "/playground/" + playground.id;
    return <div></div>;
  }
  if (!seenDescription) {
    return (
      <div>
        <NavigationBar />
        <main>
          <TileExplanation
            imageUrl={"/assets/images/quiz_tile.svg"}
            imageDescription={""}
            title={"Enquête"}
            text={
              "Om wat meer over je wensen te weten te komen hebben we een aantal vragen!"
            }
            setSeenDescription={setSeenDescription}
          />
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <NavigationBar />
        <main>
          <QuestionList playground={playground} />
        </main>
      </div>
    );
  }
};
