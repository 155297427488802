import { ContextType } from "../store/Store";

import { getUser } from "../api/user/UserCalls";
import { getPlaygrounds } from "../api/playgrounds/PlaygroundsCalls";

export const hasValidSession = (context: ContextType): boolean => {
  const { state } = context;
  let sessionToken = state.session.token;

  if (sessionToken === null) {
    sessionToken = getSessionFromStorage();
  }

  return sessionToken !== null && sessionToken !== "";
};

export const getSessionToken = (context: ContextType): string => {
  const { state } = context;
  let sessionToken = state.session.token;

  if (sessionToken === null) {
    sessionToken = getSessionFromStorage();
  }

  return sessionToken;
};

export const getSessionFromStorage = (): string => {
  let sessionToken = sessionStorage.getItem("speeltuinchef-session");
  if (sessionToken === null) {
    sessionToken = localStorage.getItem("speeltuinchef-session");
  }
  return sessionToken ? sessionToken : "";
};

export const revokeSession = (): void => {
  if (sessionStorage.getItem("speeltuinchef-session")) {
    sessionStorage.removeItem("speeltuinchef-session");
  }
  if (localStorage.getItem("speeltuinchef-session")) {
    localStorage.removeItem("speeltuinchef-session");
  }

  location.href = "/login";
};

export const checkAndLoadState = async (context: ContextType) => {
  const { state, dispatch } = context;
  if (state.session.token === null) {
    await dispatch({
      type: "SET_SESSION_TOKEN",
      payload: getSessionFromStorage(),
    });
  }
  if (state.user.email === null) {
    await userFromApiToState(context);
  }

  if (state.playgrounds === null) {
    await playgroundsFromApiToState(context);
  }
};

export const userFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();
  try {
    const response = await getUser(sessionToken);
    dispatch({ type: "SET_USER", payload: response });
  } catch (e) {
    console.log(e);
  }
};

export const playgroundsFromApiToState = async (context: ContextType) => {
  const { state, dispatch } = context;
  const sessionToken = state.session.token
    ? state.session.token
    : getSessionFromStorage();

  try {
    const response = await getPlaygrounds(sessionToken);
    dispatch({ type: "SET_PLAYGROUNDS", payload: response });
  } catch (e) {
    console.log(e);
  }
};
