import React, { useContext } from "react";
import { SpeeltuinchefContext } from "../../store/Store";
import {
  getPlaygroundById,
  getPlaygroundTiles,
} from "../../helpers/PlaygroundHelper";
import { Loading } from "../molecules/Loading";

export type PlaygroundDashboardProps = {
  playgroundId: number;
};

export const PlaygroundDashboard = (props: PlaygroundDashboardProps) => {
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(props.playgroundId, context);

  if (!playground && context.state.playgrounds !== null) {
    return (
      <div className={"block"}>
        <div className={"message message--warning"} role={"alert"}>
          U kunt niet participeren in de opgevraagde speeltuin. Dit kan komen
          omdat uw adres te ver weg ligt van de betreffende speeltuin.
        </div>
      </div>
    );
  } else if (!playground) {
    return <Loading />;
  }

  const tiles = getPlaygroundTiles(playground);

  return (
    <div className={"block"}>
      <h1>{playground.name}</h1>
      <div className={"playgroundDashboard"}>{tiles}</div>
    </div>
  );
};
