import { SpeeltuinchefState } from "../state/InitialState";
import { UserState } from "../state/UserState";
import { AddressType } from "../../models/AddressType";
import { PlaygroundType } from "../../models/PlaygroundType";

export type Action =
  | { type: "SET_SESSION_TOKEN"; payload: string }
  | { type: "SET_USER"; payload: UserState }
  | { type: "SET_USER_ADDRESS"; payload: AddressType }
  | { type: "SET_PLAYGROUNDS"; payload: Array<PlaygroundType> };

export const reducer = (state: SpeeltuinchefState, action: Action) => {
  switch (action.type) {
    case "SET_SESSION_TOKEN":
      return {
        ...state,
        session: { ...state.session, token: action.payload },
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };

    case "SET_USER_ADDRESS":
      return {
        ...state,
        user: { ...state.user, address: action.payload },
      };
    case "SET_PLAYGROUNDS":
      return {
        ...state,
        playgrounds: action.payload,
      };
    default:
      return state;
  }
};
