import React, { useEffect } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { Link } from "react-router-dom";

export const TermsPage = () => {
  useEffect(() => {
    document.title = "Algemene voorwaarden - Speeltuinchef";
  });

  return (
    <div>
      <NavigationBar />
      <main>
        <div className={"block"}>
          <p>
            <h1>Algemene voorwaarden</h1>
            Versie: februari 2020
          </p>

          <p>
            <h2>1. Algemeen</h2>
            <ol>
              <li>
                Aan het gebruik van Speeltuinchef.nl kunnen geen rechten
                ontleent worden, de participerende gemeente behoudt zich het
                recht de uitkomsten als raadgevend te beschouwen.
              </li>
              <li>
                U dient alleen een account aan te maken voor uw eigen woonadres.
                Ongeigenlijk gebruik, bijvoorbeeld door op meerdere adressen te
                registreren, is niet toegestaan en kan leiden tot uitsluiting
                van deelname.
              </li>
            </ol>
          </p>

          <p>
            <h2>2. Persoonsgegevens</h2>

            <ol>
              <li>
                De volgende persoonsgegevens worden door speeltuinchef
                verzameld: E-mail adres en woonadres.
              </li>
              <li>
                Uw adresgegevens worden verstrekt door de deelnemende gemeente.
                Het gekozen e-mail adres is optioneel. U kunt er voor kiezen een
                ongeldig (niet bestaand) e-mailadres in te vullen. U kunt de
                applicatie dan normaal gebruiken, met als uitzondering dat u
                geen updates per e-mail ontvangt vanuit de gemeente.
              </li>
              <li>IP adressen worden niet opgeslagen of verwerkt.</li>
            </ol>
          </p>

          <p>
            <h2>3. Data opslag en verwerking</h2>

            <ol>
              <li>
                De ingegeven data wordt uitsluitend gedeeld met uw gemeente, en
                niet met overige derde partijen.
              </li>
              <li>
                Speeltuinchef.nl draagt zorg om uw data zo goed mogelijk te
                beveiligen en beschermen.
              </li>
              <li>
                Gebruik van Speeltuinchef door kinderen onder de 16 jaar is
                alleen toegestaan onder toeziend oog van een ouder en/of
                verzorger
              </li>
              <li>
                De ingegeven data wordt bewaard zolang dit nodig is voor de
                realisatie van de betreffende speelplek. Mocht u uw data eerder
                willen laten verwijderen dan kunt u een verwijderingsverzoek
                indienen.
              </li>
            </ol>
          </p>

          <p>
            <h2>4. Verwijderingsverzoeken</h2>

            <ol>
              <li>
                Indien u van mening bent dat de data op Speeltuinchef
                onregtmatig verkregen is, of u wilt uw data laten verwijderen,
                neem dan contact op met info@evect.net.
              </li>
            </ol>
          </p>

          <p>
            <h2>5. Cookies</h2>
            <ol>
              <li>
                Speeltuinchef maakt geen gebruik van cookies en/of diensten van
                derde partijen.
              </li>
              <li>Speeltuinchef plaatst geen cookies.</li>
            </ol>
          </p>

          <p>
            <h2>6. Delen data</h2>
            <ol>
              <li>
                Uw ingegeven data wordt alleen gedeeld met uw eigen gemeente.
              </li>
              <li>
                Verzamelde data over een speelplek, welke niet herleidbaar is
                tot een individueel huishouden, kan worden gedeeld met
                ontwerpers van speelplekken.
              </li>
            </ol>
          </p>
          <p>
            <Link to={"/sitemap"} arial-label={"Bezoek de sitemap"}>
              Sitemap
            </Link>
          </p>
        </div>
      </main>
    </div>
  );
};
